import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  active?: boolean
  category: string
  onClick?: (e: any) => void
  title: string
}

export const Filter = memo(function Filter({
  active = false,
  category,
  onClick,
  title,
}: Props) {
  if (!title) {
    return null
  }

  return (
    <Container
      className={active ? 'active' : undefined}
      data-category={category}
      onClick={onClick}
    >
      {title}
    </Container>
  )
})

const Container = styled.div`
  position: relative;
  padding: 0 1.87vw;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 500;
  font-size: 0.93vw;
  letter-spacing: 0.1em;
  text-align: center;
  text-transform: uppercase;
  opacity: 1;
  cursor: pointer;
  transition: opacity 0.25s ease-in-out;

  &:not(:last-of-type) {
    &:after {
      @media (min-width: 1200px) {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 0.0625rem;
        height: 1.87vw;
        background-color: ${({ theme }) => theme.colors.variants.primaryDark};
      }
    }
  }

  @media (max-width: 1199px) {
    font-size: 0.6875rem;
  }

  &.active {
    opacity: 0.6;
    pointer-events: none;
  }

  &:hover {
    opacity: 0.6;
  }
`
