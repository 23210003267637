import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Close } from 'app/components/Common/Close'
import { Gallery } from 'app/components/Common/Gallery'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { CapitalLetter } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Media } from 'app/theme/media'
import useLockedBody from 'app/utils/useLockedBody'
import { useVocabularyData } from 'app/utils/vocabulary'
import { uniqBy } from 'lodash'
import React, { memo, useEffect, useState } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import Masonry from 'react-masonry-component'

import { Filter } from './Filter'

export interface CategoryProps {
  thumbnails?: ImageProps[]
  title: string
}

export interface Props {
  categories: CategoryProps[]
  languageCode: string
  title?: string
}

export const GalleryCategories = memo(function GalleryCategories({
  categories,
  languageCode,
  title,
}: Props) {
  if (!categories) {
    return null
  }

  const [_locked, setLocked] = useLockedBody()
  const [filteredCategory, setFilteredCategory] = useState('all')
  const [modalGalleryStatus, setModalGalleryStatus] = useState(false)
  const [clickedSlide, setClickedSlide] = useState<any>(null)

  let counter = 0
  let images: any = []

  const categoriesImages = categories.map((item: any) =>
    item.images.map((e: any) => images.push(e)),
  )

  useEffect(() => {
    if (modalGalleryStatus) {
      document.addEventListener('keydown', function (e) {
        switch (e.keyCode) {
          case 27:
            setLocked(false)
            setModalGalleryStatus(false)
            setClickedSlide(0)
            break
        }
      })
    }
  }, [modalGalleryStatus])

  return (
    <Container>
      {title ? (
        <TitleContainer>
          <CapitalLetter />
          <Title>{title}</Title>
        </TitleContainer>
      ) : null}
      {categories.length > 1 ? (
        <FadeInUp>
          <Filters dial={5} row wrap>
            <Filter
              active={filteredCategory === 'all'}
              category="all"
              title={useVocabularyData('all', languageCode)}
              onClick={() => {
                setFilteredCategory('all')
              }}
            />
            {uniqBy(categories, 'title').map((item, index) => {
              const category = item.title
                .replace(/[^a-zA-Z]/g, '')
                .toLocaleLowerCase()
              return category ? (
                <Filter
                  active={filteredCategory === category}
                  category={category}
                  key={index}
                  onClick={(e) => {
                    setFilteredCategory(e.target.getAttribute('data-category'))
                  }}
                  title={item.title}
                />
              ) : undefined
            })}
          </Filters>
        </FadeInUp>
      ) : null}

      <Media lessThan="desktopSmall">
        <MasonryContainer
          options={{
            columnWidth: 1,
            percentPosition: true,
            transitionDuration: 0,
          }}
        >
          {uniqBy(categories, 'title').map((list, index) => {
            const category = list.title
              .replace(/[^a-zA-Z]/g, '')
              .toLocaleLowerCase()
            if (!list.thumbnails) {
              return null
            }
            return (
              <React.Fragment key={index}>
                <Category>{list.title}</Category>
                {list.thumbnails.map((item, index) => {
                  counter = counter + 1
                  return (
                    <Thumb
                      className={
                        filteredCategory === category ||
                        filteredCategory === 'all'
                          ? 'visible'
                          : undefined
                      }
                      data-counter={counter}
                      key={index}
                      onClick={(e) => {
                        if (!modalGalleryStatus) {
                          setLocked(true)
                          setModalGalleryStatus(true)
                        } else {
                          setLocked(false)
                          setModalGalleryStatus(false)
                        }
                        const realClickedSlide =
                          Number(e.currentTarget.getAttribute('data-counter')) -
                          1
                        setClickedSlide(realClickedSlide)
                        setTimeout(() => {
                          setClickedSlide(null)
                        }, 100)
                      }}
                    >
                      <LazyLoadComponent>
                        <Image {...item} />
                      </LazyLoadComponent>
                    </Thumb>
                  )
                })}
              </React.Fragment>
            )
          })}
        </MasonryContainer>
      </Media>

      <Media greaterThanOrEqual="desktopSmall">
        <MasonryContainer
          options={{
            columnWidth: 1,
            percentPosition: true,
            transitionDuration: 0,
          }}
        >
          {uniqBy(categories, 'title').map((list, index) => {
            const category = list.title
              .replace(/[^a-zA-Z]/g, '')
              .toLocaleLowerCase()
            if (!list.thumbnails) {
              return null
            }
            return (
              <React.Fragment key={index}>
                {list.thumbnails.map((item, index) => {
                  counter = counter + 1
                  return (
                    <Thumb
                      className={
                        filteredCategory === category ||
                        filteredCategory === 'all'
                          ? 'visible'
                          : undefined
                      }
                      data-counter={counter}
                      key={index}
                      onClick={(e) => {
                        if (!modalGalleryStatus) {
                          setLocked(true)
                          setModalGalleryStatus(true)
                        } else {
                          setLocked(false)
                          setModalGalleryStatus(false)
                        }
                        const realClickedSlide =
                          Number(e.currentTarget.getAttribute('data-counter')) -
                          1
                        setClickedSlide(realClickedSlide)
                        setTimeout(() => {
                          setClickedSlide(null)
                        }, 100)
                      }}
                    >
                      <LazyLoadComponent>
                        <Image {...item} />
                      </LazyLoadComponent>
                    </Thumb>
                  )
                })}
              </React.Fragment>
            )
          })}
        </MasonryContainer>
      </Media>

      <Modal className={modalGalleryStatus ? ' open' : undefined}>
        <Close
          onClick={() => {
            setLocked(false)
            setModalGalleryStatus(false)
            setClickedSlide(0)
          }}
        />
        <Gallery
          clickedSlide={clickedSlide}
          images={images}
          isOpen={modalGalleryStatus}
        />
      </Modal>
    </Container>
  )
})

const Container = styled.section`
  padding: 11.25vw 12.5vw;
  text-align: center;

  @media (max-width: 1439px) {
    padding: 11.25vw 1.5625rem;
  }

  @media (max-width: 1199px) {
    padding: 7.5rem 1.5625rem 5rem;
  }
`

const TitleContainer = styled.div`
  position: relative;

  @media (max-width: 1199px) {
    margin-bottom: 1.25rem;
  }

  svg {
    width: 14.75vw;
    height: 18.75vw;

    @media (max-width: 1199px) {
      width: 9rem;
      height: 11.4375rem;
    }
  }
`

const Title = styled.h1`
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: normal;
  font-size: 5.31vw;
  line-height: 1.058;

  @media (max-width: 1199px) {
    font-size: 3.4375rem;
  }
`

const Filters = styled(FlexBox)`
  margin: 3.12vw 0 6.87vw;

  @media (max-width: 1199px) {
    display: none;
  }
`

const MasonryContainer = styled(Masonry)<MasonryProps>`
  margin-left: -3.12vw;
  margin-bottom: -3.12vw;

  @media (max-width: 575px) {
    margin-left: -0.9375rem;
    margin-bottom: -0.9375rem;
  }
`

const Category = styled.div`
  width: 100%;
  margin: 1.25rem 0;
  padding-left: 3.12vw;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 500;
  font-size: 0.6875rem;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  @media (max-width: 575px) {
    padding-left: 0.9375rem;
  }
`

const Thumb = styled.div`
  position: relative;
  display: none;
  width: calc(33.29% - 3.12vw);
  height: 33.75vw;
  aspect-ratio: 0.68;
  margin-bottom: 3.12vw;
  margin-left: 3.12vw;
  border-radius: 1.25vw;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    img {
      transform: scale(1.07);
    }
  }

  &.visible {
    display: block;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.25s ease-in-out;
  }

  @media (min-width: 1200px) {
    &:nth-of-type(2) {
      margin-top: 5.18vw;
    }
  }

  @media (max-width: 575px) {
    width: calc(49.9% - 0.9375rem);
    margin-bottom: 0.9375rem;
    margin-left: 0.9375rem;
  }
`

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: 0.15s ease-out;
  z-index: -1;

  &.open {
    opacity: 1;
    visibility: visible;
    z-index: 9999;
  }
`

interface MasonryProps {
  children?: React.ReactNode | React.ReactNode[]
}
